<template>
<div class="home">
   <div class="form">
      <div class="logo">o-o</div>
      <div class="slogan">Você não viu nada por aqui.</div>
      <div>
         <input class="input" placeholder="Usuário" />
      </div>
      <div>
         <input class="input" type="password" placeholder="Senha" />
      </div>
      <div class="button">ENTRAR</div>

      <div class="button ghost">Esqueceu sua senha?</div>
   </div>
</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss" scoped>
.home{
   width: 100%;
   height: 100%;
   position: absolute;
   background-color: black;
   background: url('~@/assets/background.jpg');
   background-size: cover;
   background-position: right;
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

   .form{
      max-width: 350px;
      margin-right: 128px;
      color: white;
      text-align: center;
   }

      .logo{
         font-size: 40px;
         opacity: .6;
      }
      .slogan{
         font-size: 36px;
         font-weight: bold;
         margin-top: 4px;
         margin-bottom: 24px;
      }

      .input{
         width: 240px;
         padding: 14px 20px;
         margin-top: 10px;
         box-sizing: border-box;
         border: 0;
         outline: 0;
         border-radius: 4px;
         text-transform: uppercase;
         font-size: 10px;
         color: rgba(#1A1936, .8);
      }

      .button{
         width: 240px;
         padding: 14px 20px;
         margin: 0 auto;
         margin-top: 20px;
         box-sizing: border-box;
         text-align: center;
         font-size: 12px;
         font-weight: bold;
         border-radius: 4px;
         background-color: #3A1BF3;
         color: white;
         cursor: pointer;
      }

      .button.ghost{
         background-color: transparent;
         border: 1px solid white;
         font-weight: normal;
         opacity: .3;
      }
</style>